import logo from "./logo.png";
import checkmark from "./icons/checkmark.svg";
import facebook from "./icons/facebook.svg";
import instagram from "./icons/instagram.svg";
import email from "./icons/email.svg";
import bromfiets from "./icons/bromfiets.png";
import auto from "./icons/auto.png";
import motor from "./icons/motor.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="socials">
          <img
            onClick={() =>
              (window.location.href =
                "https://www.instagram.com/theorie_043/?hl=nl")
            }
            src={instagram}
            className="App-social"
            alt="instagram"
          />
          <img
            onClick={() => (window.location = "mailto:theorie043@ziggo.nl")}
            src={email}
            className="App-social"
            alt="email"
          />

          <img
            onClick={() =>
              (window.location.href =
                "https://www.facebook.com/profile.php?id=100089236628789")
            }
            src={facebook}
            className="App-social"
            alt="facebook"
          />
        </div>
      </header>
      <div className="App-content">
        <div className="App-hero">
          <h1>THEORIE CURSUS IN MAASTRICHT</h1>
          <p>Wij geven persoonlijke begeleiding voor jouw theorie</p>
          <div className="App-usp">
            <div className="App-usp-container">
              <img src={checkmark} className="checkmark" alt="checkmark" />
              <p className="usp-item">AUTO</p>
            </div>
            <div className="App-usp-container">
              <img src={checkmark} className="checkmark" alt="checkmark" />
              <p className="usp-item">BROMFIETS</p>
            </div>
            <div className="App-usp-container">
              <img src={checkmark} className="checkmark" alt="checkmark" />
              <p className="usp-item">MOTOR</p>
            </div>
          </div>
        </div>
        <br></br>
        <p>Kies je voor deskundigheid en maatwerk?</p>
        <p>Individueel les of groepsles van maximaal 5 leerlingen</p>
        <br></br>
        <p>Meld je dan aan via:</p>
        <div>
          <p className="contact">Wanda Dohmen</p>

          <p className="contact">theorie043@ziggo.nl</p>
          <p className="contact">+31 (0) 6 25 30 05 03</p>
          <p className="contact">Touwslagersdreef 28</p>
          <p className="contact">6216 PZ Maastricht</p>

          <p className="contact">KvK: 88651649</p>
        </div>

        <div className="image-container">
          <img src={auto} className="vehicle" alt="auto" />
          <img src={bromfiets} className="vehicle" alt="bromfiets" />
          <img src={motor} className="vehicle" alt="motor" />
        </div>
        <div className="square square1"></div>
        <div className="square square2"></div>
        <div className="square square3"></div>
        <div className="square square4"></div>
      </div>
    </div>
  );
}

export default App;
